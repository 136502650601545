import logo from './logo.svg';
import './App.css';
import React, {useState, useCallback} from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import AuthContext from './shared/context/auth-context';
import MainNavigation from './shared/components/navigation/MainNavigation';

function App() {
  return (
    <AuthContext.Provider 
      value={{}} >
        <Router>
          <MainNavigation />
          <React.Fragment>
<main>
            <div className="App">
              <div>
                <h1>
                  Tamesys on the way!
                </h1>   
                <h3>[■□□□□□□□□□] 10%</h3>
                <p>We are an early stage startup from London!</p>
                <p>We will help your ideas turn into mobile app and websites :)</p>
              </div>
            </div>
          </main>
          </React.Fragment>
          
        </Router>
    </AuthContext.Provider>
    
  );
}

export default App;
