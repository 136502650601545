import React, { useContext} from "react"
import { NavLink, useNavigate } from "react-router-dom"
import AuthContext from '../../context/auth-context'

import './NavLinks.css'

const NavLinks = props => {

    const auth = useContext(AuthContext)
    const navigate = useNavigate();
    const logoutHandler = () => {
        auth.logout()
        navigate('/auth')
    }

    return <ul className="nav-links">
        <li>
            <NavLink to="/" exact>Home</NavLink>
        </li>
        
        {!auth.isLoggedIn && <li>
            <NavLink to="/auth">Sign In</NavLink>
        </li>}
        {auth.isLoggedIn && <li>
            <button onClick={logoutHandler}>LOGOUT</button>
        </li>}
    </ul>
}

export default NavLinks